<header class="header">
    <!-- <nav role="navigation" [attr.aria-label]="'nav.user.description' | translate" class="container navbar navbar-expand-md">
        <a class="navbar__brand" routerLink="/home">
          <img src="assets/anid/images/anid_rojo_azul.png" [attr.alt]="'menu.header.image.logo' | translate"/>
          <div>
            <h3>REPOSITORIO ANID</h3>
          </div>
        </a>
      <div class="d-flex flex-grow-1 ml-auto justify-content-end align-items-center">
        <ds-impersonate-navbar></ds-impersonate-navbar>
        <div class="pl-2">
          <button class="navbar-toggler" type="button" (click)="toggleNavbar()" aria-controls="collapsingNav" aria-expanded="false" [attr.aria-label]="'nav.toggle' | translate">
            <span class="navbar-toggler-icon fas fa-bars fa-fw" aria-hidden="true"></span>
          </button>
        </div>
      </div>
    </nav> -->
    <!-- <ds-themed-navbar></ds-themed-navbar> -->
  </header>
  