<footer class="text-lg-start">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-3">
        <img src="assets/custom/images/anid_rojo_azul.png" [attr.alt]="'menu.header.image.logo' | translate"/>

        <a href="https://repositorio.anid.cl">
          <img class="logo mt-3" src="assets/custom/images/repo-anid.png" [attr.alt]="'menu.header.image.logo' | translate"/>
        </a>
      </div>
      <div class="col-lg-3 col-md-3">
        <h5>Dirección</h5>
        <div class="direccion">
          <p>Moneda 1375, Santiago de Chile</p>
        </div>
      </div>
      <div class="col-lg-3 col-md-3">
        <h5>Horarios de atención</h5>
        <div class="direccion">
          <p>Lunes a viernes de 09:00 a 16:00 hrs</p>
        </div>
      </div>
      <div class="col-lg-3 col-md-3">
        <h5>Contacto</h5>
        <div class="direccion">
          <p>biblioteca@anid.cl</p>
        </div>
      </div>      
    </div>
  </div>
</footer>