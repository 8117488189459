<nav class="navbar" role="navigation" [attr.aria-label]="'nav.main.description' | translate">
  <!-- Brand Logo and Name -->
  <div class="navbar__brand">
    <a routerLink="/home" class="navbar__logo-link">
      <img src="assets/custom/images/anid_rojo_azul.png" width="200" height="60" [attr.alt]="'menu.header.image.logo' | translate"/>
    </a>
  </div>

  <!-- Navigation Links -->
  <div class="navbar__links">
    <ul class="navbar__list">
      <li class="navbar__item">
        <a routerLink="/home" class="navbar__link">Inicio</a>
      </li>
      <li class="navbar__item">
        <a routerLink="/search" class="navbar__link">Buscar</a>
      </li>
      <li class="navbar__item">
        <a routerLink="/community-list" class="navbar__link">Colecciones</a>
      </li>
    </ul>
      <ds-context-help-toggle></ds-context-help-toggle>
      <ds-themed-auth-nav-menu></ds-themed-auth-nav-menu>
      <ds-impersonate-navbar></ds-impersonate-navbar>
  </div>
</nav>