<div class="background-image-container">
    <div class="container">
      <div class="jumbotron jumbotron-fluid d-flex justify-content-center align-items-center">
          <div>
            <h1 class="display-3">Biblioteca digital ANID</h1>
            <ds-search-form 
              [inPlaceSearch]="false" 
              [searchPlaceholder]="'home.search-form.placeholder' | translate">
            </ds-search-form>
          </div>
      </div>
    </div>
    <picture class="background-image">
        <img alt="" [src]="'assets/custom/images/header_news_bg.png'"/>
    </picture>
</div>
  