import { Component } from '@angular/core';
import { NavbarComponent as BaseComponent } from '../../../../app/navbar/navbar.component';
import { slideMobileNav } from '../../../../app/shared/animations/slide';

/**
 * Component representing the public navbar
 */
@Component({
  selector: 'ds-navbar',
  styleUrls: ['./navbar.component.scss'],
  // styleUrls: ['../../../../app/navbar/navbar.component.scss'],
  templateUrl: './navbar.component.html',
  // templateUrl: '../../../../app/navbar/navbar.component.html',
  animations: [slideMobileNav]
})
export class NavbarComponent extends BaseComponent {
  r:any = document.querySelector(':root');
  increased:boolean = false
  colored:boolean = false

  // Create a function for setting a variable value
  increaseFontSize() {
    if (this.increased == true) {
      this.increased = false
      this.r.style.setProperty('--ds-font-size-normal', '1 em');
      this.r.style.setProperty('--ds-font-size-display', '2em');
      this.r.style.setProperty('--ds-font-size-h6', '1 rem');
    } else {
      this.increased = true
      this.r.style.setProperty('--ds-font-size-normal', '1.2em');
      this.r.style.setProperty('--ds-font-size-display', '2.5em');
      this.r.style.setProperty('--ds-font-size-h6', '1.2 rem');
    }
  }

  changeBackground() {
    if (this.colored == true) {
      this.colored = false
      this.r.style.setProperty('--ds-bg-blue', '#207698');
      this.r.style.setProperty('--ds-header-blue', '#001c41');
      this.r.style.setProperty('--ds-color-green', '#2cac34');
      this.r.style.setProperty('--ds-color-link', '#207698');
      this.r.style.setProperty('--blue', '#43515f');
      this.r.style.setProperty('--secondary-500', '#092039');
      this.r.style.setProperty('--primary-500', '#0F69C4');
      this.r.style.setProperty('--primary-600', '#0051A8');
    } else {
      this.colored = true
      this.r.style.setProperty('--ds-bg-blue', 'black');
      this.r.style.setProperty('--ds-header-blue', 'black');
      this.r.style.setProperty('--ds-color-green', 'black');
      this.r.style.setProperty('--ds-color-link', 'black');
      this.r.style.setProperty('--blue', 'black');
      this.r.style.setProperty('--secondary-500', 'black');
      this.r.style.setProperty('--primary-500', 'black');
      this.r.style.setProperty('--primary-600', 'black');
    }
  }
}