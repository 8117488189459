import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ThemedComponent } from '../theme-support/themed.component';
import { SearchFormComponent } from './search-form.component';

/**
 * Themed wrapper for SearchFormComponent
 */
@Component({
  selector: 'ds-themed-search-form',
  styleUrls: [],
  templateUrl: '../theme-support/themed.component.html',
})
export class ThemedSearchFormComponent extends ThemedComponent<SearchFormComponent> {

  protected inAndOutputNames: (keyof SearchFormComponent & keyof this)[] = ['query', 'inPlaceSearch', 'scope', 'currentUrl', 'large', 'brandColor', 'searchPlaceholder', 'showScopeSelector', 'submitSearch'];

  @Input() query: string;

  @Input() inPlaceSearch: boolean;

  @Input() scope: string;

  @Input() currentUrl: string;

  @Input() large = false;

  @Input() brandColor = 'primary';

  @Input() searchPlaceholder: string;

  @Input() showScopeSelector = false;

  @Output() submitSearch: EventEmitter<any> = new EventEmitter<any>();

  protected getComponentName(): string {
    return 'SearchFormComponent';
  }

  protected importThemedComponent(themeName: string): Promise<any> {
    return import(`../../../themes/${themeName}/app/shared/search-form/search-form.component`);
  }

  protected importUnthemedComponent(): Promise<any> {
    return import('./search-form.component');
  }
}